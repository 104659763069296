<template>
  <div class="fw otherpage">
    <AmeniNav :isOtherPage="true"></AmeniNav>
    <div class="fw__inner otherpage__inner">
      <h1 class="fw__heading heading otherpage__heading">Front-end <br class="sp-block">Works</h1>
      <ul class="fw__lead otherpage__list wave-line">
        <li>年代別に掲載しています。</li>
        <li>2021年以降…フリーランスとしての実績 <span class="em">(掲載可能なもののみ)</span></li>
        <li>2021年以前…企業所属の会社員としての実績</li>
      </ul>
      <div class="fw__block fw__block--1 wave-line">
        <h2 class="fw__secondary-heading">2021〜</h2>
        <ul class="fw__thumb-lists">
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/42/1.png" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">株式会社TSUNAG様<br>コーポレートサイト<br>MVモーション</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="2"
              data-imglength="1"
              data-id="42"
            >
              ■ 担当箇所：イントロモーション、MVモーションのマークアップ / js<br />
              ■ 対応デバイス：PC/SP/Tablet<br />
              ■ 対応箇所<br />
              イントロモーション<br>
              MVモーション<br>
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/35/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">東京地下ラボ<br>イベント特設サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="2"
              data-imglength="1"
              data-id="35"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC/SP/Tablet<br />
              ■ 機能：<br />
              パララックス<br>
              MVのcanvasモーション<br>
              SVGモーション<br>
              cssアニメーション
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/37/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">東京地下ラボ<br>イベント後 作品展示サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="37"
              data-size="large"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC/SP/Tablet<br />
              ■ 機能：<br />
              canvasモーション、パララックス、web漫画(スライダー実装)
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/36/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">八ヶ岳WORK&LIFE</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="2"
              data-imglength="1"
              data-id="36"
            >
              ■ 担当箇所：マークアップ / js (Wordpress導入前のフロント構築のみ)<br />
              ■ 対応デバイス：PC/SP/Tablet<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/38/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">若者フォーラム2021</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="38"
              data-size="large"
            >
              ■ 担当箇所：マークアップ / js / PHP<br />
              ■ 対応デバイス：PC/SP/Tablet<br />
              ■ 機能：<br />
              canvasモーション、cssモーション、パララックス、キーワード入力によるページの振り分け
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/39/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">beardpapa's fresh'n natural cream puffs</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="39"
              data-size="large"
            >
              ■ 担当箇所：マークアップ / js / wordpress<br />
              ■ 対応デバイス：PC/SP/Tablet<br />
              ■ 機能：<br />
              動画の遅延ロード、スクロールに合わせたモーション、wordpressオリジナルテンプレート作成
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/40/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">株式会社iMAGE様ティザーサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="4"
              data-id="40"
              data-size="large"
            >
              ■ 担当箇所：マークアップ / js<br>
              ■ 対応デバイス：PC/SP/Tablet<br />
              ■ 機能：<br />
              パララックス、スクロール芸モーション
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/41/1.png" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">合同会社ヤツガタケシゴトニン様<br>コーポレートサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="2"
              data-imglength="1"
              data-id="41"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC/SP/Tablet<br />
              ■ js対応：<br />
              MVモーション<br>
              スクロールに合わせたモーションなど<br>
              cssアニメーション
            </div>
          </li>
        </ul>
      </div>
      <div class="fw__block fw__block--1 wave-line">
        <h2 class="fw__secondary-heading">2017〜2019</h2>
        <div class="otherpage__lead">
          <p>
            様々なwebページ構築に携わりました。<br />
            コーポレートサイト、ゲームのオフィシャルページ、スペシャルサイト、ゲームのポータルサイト、ゲームのECサイト、ソーシャルゲーム内のwebページ、等。
          </p>
          <p>
            <span class="em"
              >大変申し訳ございませんが、2015年〜2019年は制作物のデータを保存しておかなかったため、制作に携わったものを少ししか掲載できていません。</span
            >
          </p>
        </div>
        <ul class="fw__thumb-lists">
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/31/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">ソーシャルゲームの特設ページ</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="2"
              data-imglength="1"
              data-id="31"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC<br />
              ■ 機能：<br />
              パララックス<br />
              スクロールに合わせて薔薇が咲くモーション
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/32/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">ゲームポータルサイト内コンテンツ</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="6"
              data-id="32"
            >
              ■ 担当箇所：js<br />
              ■ 対応デバイス：PC<br />
              ■ 機能：<br />
              スクロールの挙動（1スクロールで1画面ごとに遷移する）<br />
              スライダー、アバターの着替え(API)、春夏秋冬の背景モーション（桜が舞う、夏の日差しがキラキラ、枯葉が落ちる、雪が降る）
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/33/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">ゲームポータルサイト特設ページ</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="2"
              data-imglength="1"
              data-id="33"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC<br />
              ■ 機能：<br />
              マウスに合わせてキャラクターが動く（パララックス）<br />
              canvasモーション、スクロールに合わせて要素を表示
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/34/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">ゲームポータルサイトwebアプリ版</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="34"
            >
              ■ 担当箇所：サイト内コンテンツのフロント構築(react)<br />
              ■ 対応デバイス：SP
            </div>
          </li>
        </ul>
      </div>
      <div class="fw__block wave-line">
        <h2 class="fw__secondary-heading">2015〜2016</h2>
        <div class="otherpage__lead">
          <p>
            プロダクトページ、サービス開発、ソーシャルゲーム、ゲームのオフィシャルサイト、等に携わる。
          </p>
        </div>
      </div>
      <div class="fw__block wave-line">
        <h2 class="fw__secondary-heading">2014</h2>
        <ul class="fw__thumb-lists">
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/23/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">コーポレートメディアページ</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="23"
            >
              担当箇所：マークアップ<br />
              対応デバイス：PC,tablet,SP<br />
              レスポンシブ
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/24/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">コーポレート採用サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="5"
              data-id="24"
            >
              担当箇所：マークアップ / js<br />
              対応デバイス：PC,tablet,SP<br />
              レスポンシブ
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/25/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">ドラマ公式サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="4"
              data-id="25"
            >
              担当箇所：Tumblrテンプレート改修<br />
              対応デバイス：PC,tablet,SP<br />
              レスポンシブ
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/26/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">技術メディアサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="2"
              data-id="26"
            >
              担当箇所：アクセシビリティチェック<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/27/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">対談記事特設サイトリニューアル</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="6"
              data-id="27"
            >
              担当箇所：マークアップ / js<br />
              対応デバイス：PC,tablet,SP<br />
              レスポンシブ
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/28/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">プロダクトメディアサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="4"
              data-id="28"
            >
              担当箇所：Tumblrテンプレート改修<br />
              対応デバイス：PC,tablet,SP<br />
              レスポンシブ
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/29/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">市民音楽祭紹介メディアサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="4"
              data-id="29"
            >
              担当箇所：マークアップ<br />
              対応デバイス：PC<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/30/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">メディアサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="30"
            >
              担当箇所：マークアップ / js<br />
              対応デバイス：PC,tablet,SP<br />
              レスポンシブ
            </div>
          </li>
        </ul>
      </div>
      <div class="fw__block wave-line">
        <h2 class="fw__secondary-heading">2012〜2013</h2>
        <ul class="fw__thumb-lists">
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/12/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">カスタムヘッドフォンECサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="5"
              data-id="12"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC,tablet,SP<br />
              ■ レスポンシブ<br />
              ■ 機能：<br />
              pjaxでのモーション付き遷移<br />
              APIからJSONを取得し、デザインに反映<br />
              検索システム、管理画面からの自動デザイン変更
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/13/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">ユーザー投稿サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="5"
              data-id="13"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC,SP<br />
              ■ 機能：<br />
              ユーザーが画像を投稿できる<br />
              APIから取得したデータを元にデザイン反映<br />
              ユーザーから投稿された画像が流れて表示される
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/14/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">イベントサイトティザーページ</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="4"
              data-id="14"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC,tablet,SP<br />
              ■ 機能：<br />
              画像がランダムでバラバラと画面内に表示され、最後の画面でイベント告知の動画が流れる。<br />
              動画が流れている間も、背景の画像は一定時間をおいて少しづつ違う画像に変化する。
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/15/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">コーポレートサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="4"
              data-id="15"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC,tablet,SP<br />
              ■ 機能：<br />
              一定時間置きに■が飛び散るアニメーションを実装
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/16/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">期間限定スペシャルサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="16"
            >
              ■ 担当箇所：静的ページコーディング<br />
              ■ 対応デバイス：PC<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/17/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">期間限定サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="17"
            >
              ■ 担当箇所：マークアップ<br />
              ■ 対応デバイス：PC<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/18/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">期間限定スペシャルサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="18"
            >
              ■ 担当箇所：静的下層ページ約20ページマークアップ（PCのみ）<br />
              ■ 対応デバイス：PC、SP<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/19/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">プロダクトサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="3"
              data-id="19"
            >
              ■ 担当箇所：下層一部のページ(マークアップ、js)<br />
              ■ 対応デバイス：PC<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/20/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">アプリ紹介PCページ</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="20"
            >
              ■ 担当箇所：マークアップ / js<br />
              ■ 対応デバイス：PC<br />
              ■ リキッドレイアウト
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/21/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">プロダクトスペシャルサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="2"
              data-id="21"
            >
              ■ 担当箇所：IE6、7用の静的ページマークアップ<br />
              ■ 対応デバイス：PC<br />
              ■ リキッドレイアウト
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/22/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">プロダクトスペシャルサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="2"
              data-id="22"
            >
              ■ 担当箇所：<br />
              IE6、7用の静的ページマークアップ、スライダー一部実装<br />
              ■ 対応デバイス：PC<br />
            </div>
          </li>
        </ul>
      </div>
      <div class="fw__block">
        <h2 class="fw__secondary-heading">2010〜2011</h2>
        <ul class="fw__thumb-lists">
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/1/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">高速バスチケット販売サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="1"
            >
              ■ 担当箇所：定期更新のコーディング<br />
              ■ 対応デバイス：PC(IE6+)<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/2/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">バスチケット販売サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="2"
              data-id="2"
            >
              ■ 担当箇所：マークアップ、イラスト制作、デザイン<br />
              ■ 対応デバイス：PC(IE6+)<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/3/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">医学イベントティザーページ</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="3"
            >
              ■ 担当箇所：マークアップ、デザイン<br />
              ■ 対応デバイス：PC(IE6+)<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/4/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text"
                >キャンペーンサイト<br />
                (フィーチャーフォン対応)</span
              >
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="4"
            >
              ■ 担当箇所：更新作業(ActionScriopt2のUA判定、微調整など)<br />
              ■ 対応デバイス：フィーチャーフォン<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/5/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">医学イベントサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="5"
            >
              ■ 担当箇所：マークアップ、デザイン、Flash<br />
              ■ 対応デバイス：PC(IE6+)<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/6/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span>
              <span class="text">SNSモバイルゲーム</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="6"
            >
              ■ 担当箇所：静的ページコーディング<br />
              ■ 対応デバイス：フィーチャーフォン<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/7/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">化粧品ブランドサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="7"
            >
              ■ 担当箇所：デザイン、マークアップ<br />
              ■ 対応デバイス：PC(IE6+)<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/8/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">化粧品ブランドサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="8"
            >
              ■ 担当箇所：マークアップ、デザイン、Flash<br />
              ■ 対応デバイス：PC(IE6+)、SP、フィーチャーフォン<br />
              ■詳細<br />
              新規ページ作成、ECシステムページの修正、キャンペーンサイト、Flashバナー制作など
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/9/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">化粧品ブランド メールマガジン</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="9"
            >
              ■ 担当箇所：マークアップ、デザイン<br />
              ■ 対応デバイス：PC(IE6+)、SP、フィーチャーフォン<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/10/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">医学イベントサイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="10"
            >
              ■ 担当箇所：マークアップ<br />
              ■ 対応デバイス：PC(IE6+)、フィーチャーフォン<br />
            </div>
          </li>
          <li class="fw__thumb-list" @click="onModal">
            <div class="fw__thumb-wrap">
              <img src="../assets/img/frontworks/11/1.jpg" />
            </div>
            <p class="fw__tumb-ttl">
              <span class="ico">coding</span><span class="ico second">design</span>
              <span class="text">個人運営素材配布サイト</span>
            </p>
            <div
              class="fw__modaldesc"
              data-type="1"
              data-imglength="1"
              data-id="11"
            >
              ■ 担当箇所：デザイン、マークアップ、WordPress組み込み<br />
              ■ 対応デバイス：PC(IE7+)<br />
            </div>
          </li>
        </ul>
      </div>
    </div>

    <WorksModal
      v-if="isModal"
      @onClose="onClose"
      :isMovie="isMovie"
      :imgLength="imgLength"
      :ModaldescText="ModaldescText"
      :dataID="dataID"
      :title="title"
      :size="size"
    >
    </WorksModal>

    <Footer :styleType="2"></Footer>
  </div>
</template>

<script>
import setMetaDesc from "@/mixin/setMetaDesc";
import AmeniNav from "@/components/AmeniNav";
import Footer from "@/components/Footer";
import WorksModal from "@/components/WorksModal";

export default {
  mixins: [setMetaDesc],
  components: {
    AmeniNav,
    Footer,
    WorksModal,
  },
  data() {
    return {
      isModal: false,
      isMovie: false,
      imgLength: 0,
      ModaldescText: "",
      modalHtmlElement: "",
      dataID: 0,
      title: "",
      size: "",
    };
  },
  methods: {
    onModal(e) {
      e.preventDefault();
      const html = document.querySelector(".html");
      html.style.overflow = "hidden";
      this.isModal = true;
      this.modalHtmlElement = e.currentTarget.querySelector(".fw__modaldesc");
      const title = e.currentTarget.querySelector(".fw__tumb-ttl");
      const ttlText = title.querySelector(".text").innerHTML;
      this.title = ttlText;
      this.ModaldescText = this.modalHtmlElement.innerHTML;
      const dataType = Number(this.modalHtmlElement.dataset.type);
      const dataLength = Number(this.modalHtmlElement.dataset.imglength);
      this.size = this.modalHtmlElement.dataset.size;
      this.dataID = Number(this.modalHtmlElement.dataset.id);
      this.isMovie = dataType == 1 ? false : true;
      if (!this.isMovie) {
        this.imgLength = dataLength;
      }
      //後で余裕があったらハッシュタグで表示切り替え対応しておく
      // const hash = '#' + this.dataID;
      // location.hash = hash;
    },
    onClose() {
      const html = document.querySelector(".html");
      html.style.overflow = "auto";
      this.isModal = false;
      //後で余裕があったらハッシュタグで表示切り替え対応しておく
      //location.hash = '';
    },
  },
};
</script>

<style lang="scss">
.fw {
  .nav {
    margin-top: 30px;
  }
  .em {
    background-color: #f8f0eb!important;
  }
  &__lead {
    margin-bottom: 60px;
    @include max-screen($sp) {
      margin-bottom: 30px;
    }
  }
  &__block {
    margin-bottom: 80px;
    @include max-screen($sp) {
      margin-bottom: 40px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &--1 {
      padding-bottom: 50px;
    }
  }
  &__secondary-heading {
    font-size: 22px;
    padding-left: 20px;
    position: relative;
    margin-bottom: 40px;
    @include max-screen($sp) {
      font-size: 18px;
      padding-left: 16px;
      margin-bottom: 15px;
    }
    &:before {
      width: 5px;
      border-radius: 2px;
      background-color: #f1dcd3;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      content: "";
    }
  }
  &__thumb-wrap {
    width: 180px;
    min-height: 120px;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max-screen($sp) {
      margin-bottom: 5px;
      width: 100%;
    }
    img {
      width: 100%;
      display: block;
    }
    video {
      width: 100%;
    }
  }
  &__tumb-ttl {
    font-size: 12px;
    overflow-wrap: break-word;
    line-height: 22px;
    @include max-screen($sp) {
      line-height: 18px;
    }
    .ico {
      background-color: #e8ebdc;
      color: #839149;
      font-size: 12px;
      display: inline-block;
      text-align: center;
      height: 20px;
      line-height: 20px;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 0 10px;
      margin-right: 5px;
      &.second {
        background-color: #fcf7df;
        color: #d6b931;
      }
    }
    .text {
      width: 100%;
      display: block;
      @include max-screen($sp) {
        padding-top: 5px;
      }
    }
  }
  &__thumb-lists {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    @include max-screen($sp) {
      justify-content: center;
    }
  }
  &__thumb-list {
    margin-bottom: 30px;
    padding: 10px;
    box-sizing: border-box;
    max-width: 200px;
    @include max-screen($sp) {
      margin-bottom: 15px;
      width: 50%;
    }
    &:nth-child(4) {
      margin-right: 0;
    }
    &:nth-child(odd) {
      @include max-screen(430px) {
        padding: 5px 10px 5px 0;
      }
    }
    &:nth-child(even) {
      @include max-screen(430px) {
        padding: 5px 0 5px 10px;
      }
    }
    &:hover {
      background-color: #eee;
      cursor: pointer;
      @include max-screen($sp) {
        background-color: inherit;
      }
    }
  }
  &__modaldesc {
    height: 0;
    width: 0;
    overflow: hidden;
    text-indent: -9999px;
  }
}
</style>
